<template>
  <b-card title="Buscar persona">
    <b-row>
      <b-col cols="10">
        <vue-autosuggest
          ref="autocomplete"
          v-model="query"
          :suggestions="suggestions"
          :input-props="inputProps"
          :section-configs="sectionConfigs"
          :render-suggestion="renderSuggestion"
          :get-suggestion-value="getSuggestionValue"
          @input="fetchResults"
        />

      </b-col>
      <b-col cols="2">
        <b-button
          variant="primary"
          block
          @click="nuevaAtencionSoporte"
        >
          Agregar
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import axios from '@axios'

export default {
  components: {
    VueAutosuggest,
    BRow,
    BCol,
    BCard,
    BButton,
  },
  data() {
    return {
      query: '',
      results: [],
      timeout: null,
      selected: null,
      debounceMilliseconds: 500,
      photosUrl: '/buscar-persona',
      inputProps: {
        id: 'autosuggest__input_ajax',
        placeholder: 'Ingresar número de indentificación, código ó apellidos?',
        class: 'form-control',
        name: 'ajax',
      },
      suggestions: [],
      sectionConfigs: {
        personas: {
          limit: 30,
          label: 'Alumnos',
          onSelected: selected => {
            this.selected = selected.item
          },
        },
        docentes: {
          limit: 30,
          label: 'Docentes',
          onSelected: selected => {
            this.selected = selected.item
          },
        },
      },
    }
  },
  methods: {
    nuevaAtencionSoporte() {
      if (this.selected) {
        this.$emit('set-add-soporte', this.selected)
        this.$root.$emit('bv::show::modal', 'modal-soporte-form', '#refModalSoporteForm')
        this.selected = null
        document.getElementsByName('ajax')[0].value = null
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Aviso!',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
            text: 'Por favor seleccionar un estudiante.',
          },
        })
      }
    },
    fetchResults() {
      const { query } = this

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const personasPromise = this.$http.get(`${this.photosUrl}/${query}`)

        Promise.all([personasPromise]).then(values => {
          this.suggestions = []
          this.selected = null

          const personas = values[0].data.data[0]
          const docentes = values[0].data.data[1]
          // eslint-disable-next-line no-unused-expressions
          personas.length
            && this.suggestions.push({ name: 'personas', data: personas })
          // eslint-disable-next-line no-unused-expressions
          docentes.length
            && this.suggestions.push({ name: 'docentes', data: docentes })
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${error.response.data.error}`,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
      }, this.debounceMilliseconds)
    },
    renderSuggestion(suggestion) {
      return (
        <div>{suggestion.item.apellido_paterno} {suggestion.item.apellido_materno}, {suggestion.item.nombres}
        <span class="text-danger"><strong>({suggestion.item.numero_documento})</strong></span>
        </div>
      )
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return `${item.apellido_paterno} ${item.apellido_materno}, ${item.nombres} `
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
