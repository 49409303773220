import { render, staticRenderFns } from "./SoporteSocialEvidenciasModal.vue?vue&type=template&id=52bf1cdb&scoped=true&"
import script from "./SoporteSocialEvidenciasModal.vue?vue&type=script&lang=js&"
export * from "./SoporteSocialEvidenciasModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52bf1cdb",
  null
  
)

export default component.exports