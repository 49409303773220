import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // index
    fetchSoportes(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/servicio-social/asistencia-social/soporte', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // show
    fetchSoporteSocial(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-social/asistencia-social/soporte/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // store
    addSoporte(ctx, soporteData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/servicio-social/asistencia-social/soporte', soporteData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // update
    updateSoporte(ctx, soporteData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/servicio-social/asistencia-social/soporte/${soporteData.id}`, soporteData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // delete
    deleteSoporte(ctx, visitaData) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/servicio-social/asistencia-social/soporte/${visitaData.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
