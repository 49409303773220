<template>
  <div>
    <buscar-persona
      v-show="mostrarBuscar"
      @set-add-soporte="(val) => persona = val"
    />

    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                type="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-visitas
                :variant="mostrarBuscar ? 'warning' : 'info' "
                @click="()=>mostrarBuscar = !mostrarBuscar"
              >
                <span class="text-nowrap">{{ mostrarBuscar ? 'Ocultar' : 'Nuevo registro' }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <!-- Main table element -->
      <b-table
        id="my-table"
        ref="refTablaUsuarios"
        class="position-relative"
        show-empty
        head-variant="light"
        hover
        small
        responsive
        :busy.sync="isBusy"
        :items="myProvider"
        :fields="fields"
        :no-provider-filtering="true"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :height="myProvider.length < 5 ? '200px' : null"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Cargando...</strong>
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(persona_usuario)="data">
          <!-- <pre>{{ data.item.persona_usuario }}</pre> -->
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :variant="`light-${resolveUserServicioVariant(data.item.persona_usuario.nombres || '')}`"
                :text="avatarText(data.item.persona_usuario.nombres || '')"
              />
            </template>
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.persona_usuario.nombre_completo }}
            </b-link>
            <small class="text-muted"><strong>DOC.IDENTIDAD:</strong> {{ data.item.persona_usuario.numero_documento }}</small> <br>
            <small class="text-muted"><strong>CEL: </strong>{{ data.item.persona_usuario.celular || 'sin registrar' }}</small>
          </b-media>
        </template>

        <template #cell(fecha)="data">

          {{ new Intl.DateTimeFormat('es-PE',{month: 'long', day:'numeric', year:'numeric'}).format(new Date(`${data.item.fecha}T00:00:00`)) }} <br>
        </template>
        <template #cell(etnia)="data">
          {{ data.item.persona_usuario.etnia }}
        </template>
        <template #cell(escuela)="data">
          {{ data.item.escuela.nombre }}
        </template>

        <!-- Column: Actions -->
        <template #cell(acciones)="data">
          <span><feather-icon
            :icon="data.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
            size="19"
            @click="data.toggleDetails"
          /></span>
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="editarAsistencia(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="cargarEvidencias(data.item)"
            >
              <feather-icon icon="ImageIcon" />
              <span class="align-middle ml-50">Evidencias</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="descargarPdf(data.item.id)"
            >
              <feather-icon icon="PrinterIcon" />
              <span class="align-middle ml-50">Imprimir</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="deleteItem(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template
          #row-details="data"
          class="m-0 p-0"
        >
          <b-card class="m-0 p-0 border">
            <b-row>
              <b-col
                cols="12"
                class="text-center mb-1"
              >
                <span class="text-primary"><strong>SERVIDOR QUE REGISTRÓ: </strong> </span>
                <span class="ml-1">{{ data.item.servidor_publico.name }}</span>
              </b-col>
              <b-col
                cols="12"
                class="mb-1"
              >
                <!-- <feather-icon
                  icon="DiscIcon"
                  size="16"
                  class="text-primary mr-1"
                /> -->
                <li><span class="text-primary"><strong>MOTIVO: </strong> </span></li>
                <span class="ml-2">{{ data.item.motivo }}</span>
              </b-col>
              <b-col
                cols="12"
                class="mb-1"
              >
                <!-- <feather-icon
                  icon="DiscIcon"
                  size="16"
                  class="text-primary mr-1"
                /> -->
                <li><span class="text-primary"><strong>DETALLES:</strong>  </span></li>
                <span class="ml-2">{{ data.item.detalles }}</span>
              </b-col>
              <b-col
                cols="12"
                class="mb-1"
              >
                <!-- <feather-icon
                  icon="DiscIcon"
                  size="16"
                  class="text-primary mr-1"
                /> -->
                <li><span class="text-primary"><strong> CONCLUSIONES:</strong> </span></li>
                <span class="ml-2">{{ data.item.conclusiones }}</span>
              </b-col>
            </b-row>
          </b-card>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>

          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>

    </b-card>
    <soporte-social-form-modal
      :persona="persona"
      :edited-item="editedItem"
      @refresh-data-visitas-table="actualizarTabla"
    />
    <soporte-social-evidencias-modal
      :persona="persona"
      :edited-item="editedItem"
      @refresh-data-visitas-table="actualizarTabla"
    />
  </div>
</template>

<script>
import {
  BCard, BTable, BSpinner, VBModal, BRow, BCol, BFormInput, BDropdown, BDropdownItem, BPagination, BMedia,
  BAvatar, BLink, BButton,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import BuscarPersona from './BuscarPersona.vue'
import soporteSocialStoreModule from './soporteSocialStoreModule'
import SoporteSocialFormModal from './SoporteSocialFormModal.vue'
import SoporteSocialEvidenciasModal from './SoporteSocialEvidenciasModal.vue'

export default {
  components: {
    BTable,
    SoporteSocialFormModal,
    SoporteSocialEvidenciasModal,
    BButton,
    vSelect,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    BAvatar,
    BLink,

    // componentes local
    BuscarPersona,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      mostrarBuscar: false,
      persona: {},
      editedItem: {},
      isBusy: false,
      mostrar: false,
      items: [],
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [50, 100, 300, 500],
      sortBy: 'id',
      sortDesc: false,
      sortDirection: 'desc',
      filter: '',
      filterOn: [],
      selectedPersona: {},
    }
  },
  methods: {

    editarAsistencia(item) {
      const persona = item.persona_usuario
      this.editedItem = { ...item, persona }
      this.$root.$emit('bv::show::modal', 'modal-soporte-form', '#refModalSoporteForm')
    },
    cargarEvidencias(item) {
      const persona = item.persona_usuario
      this.editedItem = { ...item, persona }
      this.$root.$emit('bv::show::modal', 'modal-soporte-evidencia', '#refModalSoporteEvidencia')
    },
    descargarPdf(item) {
      this.$router.replace({ name: 'download-pdf-soporte-social', params: { id: item } })
    },
    deleteItem(item) {
      this.$swal({
        title: '¿Estas seguro?',
        text: 'No podrás revertir esto',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, borrar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('soporte-social/deleteSoporte', item)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Eliminado!',
                text: 'Registro eliminado correctamente.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.actualizarTabla()
            }).catch(error => {
              this.$swal({
                title: 'Error',
                text: `${error}`,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Tu registro está seguro :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    actualizarTabla() {
      this.filter = null
      this.$refs.refTablaUsuarios.refresh()
    },
    async myProvider(ctx) {
      const promise = store.dispatch('soporte-social/fetchSoportes', { queryParams: ctx })

      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data
        // Pluck the array of items off our axios response
        const items = data

        this.totalRows = total || 0
        // this.from = meta.pagination.count
        this.from = from || 0

        this.to = to || 0
        // Must return an array of items or an empty array if an error occurred
        return items || []
      })
    },
  },
  setup() {
    const SOPORTE_SOCIAL_APP_STORE_MODULE_NAME = 'soporte-social'

    // Register module
    if (!store.hasModule(SOPORTE_SOCIAL_APP_STORE_MODULE_NAME)) store.registerModule(SOPORTE_SOCIAL_APP_STORE_MODULE_NAME, soporteSocialStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SOPORTE_SOCIAL_APP_STORE_MODULE_NAME)) store.unregisterModule(SOPORTE_SOCIAL_APP_STORE_MODULE_NAME)
    })

    const fields = [
      { key: 'persona_usuario', label: 'estudiante', sortable: true },
      { key: 'fecha', label: 'fecha', sortable: true },
      { key: 'escuela', label: 'escuela', sortable: true },
      { key: 'etnia', label: 'etnia', sortable: true },
      { key: 'acciones' },
    ]

    const resolveUserServicioVariant = () => 'primary'

    return {
      avatarText,
      fields,
      // soporteSocialStoreModule,
      resolveUserServicioVariant,
    }
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
